import { useLocalStorage } from '@vueuse/core';

export default async (key, initialValue = '') => {
  let useLocalStoragePromise = Promise.resolve(useLocalStorage(key));
  const cached = await useLocalStoragePromise;
  if (initialValue) {
    cached.value = initialValue;
  }
  return cached.value;
};
